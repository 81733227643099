<script>
import { inView, spring, timeline } from 'motion'

export default {

  mounted() {
    const glass1 = this.$el.querySelectorAll('.glass1')
    const glass2 = this.$el.querySelectorAll('.glass2')
    const liquid1 = this.$el.querySelectorAll('.liquid1')
    const liquid2 = this.$el.querySelectorAll('.liquid2')

    const sequence = [
      [glass1, { translateX: [0, 50], rotate: [-40, 0] }, { duration: 1, easing: spring({ velocity: 100 }) }],
      [liquid1, { translateX: [-39, 0], translateY: [62, 0], rotate: [40, 0] }, { duration: 1, at: 0 }],
      [glass2, { translateX: [120, 50], rotate: [20, 0] }, { duration: 1, at: 0, easing: spring({ velocity: 100 }) }],
      [liquid2, { translateX: [24, 0], translateY: [-25, 0], rotate: [-20, 0] }, { duration: 1, at: 0 }],
    ]

    const root = this.$el

    inView(root, () => {
      const theTimeline = timeline(sequence)

      return () => theTimeline.stop()
    })
  },
}
</script>

<template>
  <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 300 200">

    <g class="glass1 text-glueblue-600 origin-center">
      <rect
        fill="currentColor" x="10.64" y="128.75" width="45.74" height="2.28"
        transform="translate(-100.46 136.68) rotate(-78.43)"
      />
      <rect
        fill="currentColor" x="27.87" y="133.65" width="2.09" height="37.29"
        transform="translate(-126.09 152.84) rotate(-79.45)"
      />
      <path
        fill="currentColor"
        d="m45.98,101.82c-2.47,0-4.99-.23-7.53-.7-19.68-3.67-33.08-20.72-29.85-38.03l.21-1.12,71.39,13.29-.21,1.12c-2.81,15.07-17.31,25.43-34.01,25.43ZM10.65,64.64c-2.22,15.68,10.2,30.89,28.22,34.25,18.02,3.36,35.08-6.37,38.65-21.8l-66.87-12.45Z"
      />
      <rect
        fill="currentColor" x="28.61" y="129.69" width="2.09" height="37.29"
        transform="translate(-121.6 150.34) rotate(-79.45)"
      />
      <rect class="liquid1 origin-center" fill="currentColor" x="13.82" y="82.96" width="61.96" height="2.28" />
      <rect class="liquid1 origin-center" fill="currentColor" x="19.31" y="89.22" width="52.08" height="2.28" />
      <path
        fill="currentColor"
        d="m45.45,109.03c-2.75,0-5.54-.25-8.33-.77C12.49,103.67-3.81,79.9.77,55.28l.21-1.12,89.33,16.63-.21,1.12c-4.06,21.83-23.2,37.12-44.65,37.12ZM2.82,56.82c-3.66,22.99,11.7,44.92,34.72,49.2,23.03,4.29,45.25-10.64,50.1-33.41L2.82,56.82Z"
      />
    </g>
    <g class="glass2 text-red-500 origin-center">
      <path
        fill="currentColor"
        d="m94.75,56.06c-15.1,0-29.24-9.5-34.39-24.56l-.37-1.08L128.71,6.92l.37,1.08c3.14,9.18,2.52,19.03-1.75,27.73-4.27,8.71-11.68,15.23-20.85,18.37-3.88,1.33-7.83,1.96-11.72,1.96Zm-31.85-24.23c6.47,17.06,25.44,26.07,42.83,20.12,8.6-2.94,15.54-9.06,19.54-17.22,3.83-7.82,4.53-16.62,1.99-24.91L62.91,31.83Z"
      />
      <rect
        fill="currentColor" x="114.08" y="57.17" width="2.28" height="44.83"
        transform="translate(-19.51 41.41) rotate(-18.81)"
      />
      <rect
        fill="currentColor" x="103.8" y="99.66" width="37.29" height="2.28"
        transform="translate(-26.02 45.02) rotate(-18.87)"
      />
      <rect
        fill="currentColor" x="102.56" y="95.95" width="37.29" height="2.28"
        transform="translate(-24.89 44.42) rotate(-18.87)"
      />
      <rect class="liquid2 origin-center" fill="currentColor" x="65.49" y="38.18" width="58.32" height="2.28" />
      <rect class="liquid2 origin-center" fill="currentColor" x="70.08" y="44.44" width="49.12" height="2.28" />
      <path
        fill="currentColor"
        d="m95.44,61.16c-18.33,0-36.02-12.69-42.78-32.47l-.36-1.06L133.09,0l.36,1.06c4.03,11.78,3.63,24.29-1.13,35.23-4.77,10.98-13.35,19.06-24.16,22.75-4.19,1.43-8.48,2.12-12.72,2.12ZM55.15,29.02c8.28,22.52,31.5,35.02,52.29,27.9,10.21-3.49,18.32-11.14,22.83-21.53,4.39-10.1,4.88-21.62,1.41-32.56L55.15,29.02Z"
      />
    </g>
  </svg>
</template>
